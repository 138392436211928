
import React from "react";
import { navigate } from "gatsby";

import ContentContainer from "../shared/ContentContainer";
import ContentTitle from "../shared/ContentTitle";
import ContentSubTitle from "../shared/ContentSubTitle";
import ContentParagraph from "../shared/ContentParagraph";
import ContentButton from "../shared/ContentButton";

import { getCookie } from "../../../helpers";

const IntroductionContent = () => {
    const isAcronisTrial = getCookie('trial_type') === 'acronis';
    const tenantCreationDurationMinutes = isAcronisTrial ? 30 : 10;

    return (
        <ContentContainer>
            <ContentTitle>
                Thank you for registering to Frends!
            </ContentTitle>
            <ContentSubTitle>
                Try all you want for 30 days!
            </ContentSubTitle>
            <ContentParagraph>
                {`In order to get started with trial you will need to select your Frends tenant name after clicking Get trial button. The trial creation takes approximately ${tenantCreationDurationMinutes} minutes, after which you'll receive a welcome email to your trial and you can manage your trial in this view.`}
            </ContentParagraph>
            <ContentButton onClick={() => navigate('/profile/trial/create')}>
                Get trial
            </ContentButton>
        </ContentContainer>
    );
};

export default IntroductionContent;