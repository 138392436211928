import React from "react";

import useTrialAuthClaimCheck, { INTRODUCTION_PATH } from "../../../components/profile/shared/AuthClaimCheckHook";
import PageContentContainer from "../../../components/profile/shared/PageContentContainer";
import IntroductionContent from "../../../components/profile/trial/IntroductionContent";

import { pushSAEvent } from "../../../helpers";

export default ({ location }) => {
    const loading = useTrialAuthClaimCheck(INTRODUCTION_PATH);

    const menuItems = [
        {
            path: INTRODUCTION_PATH,
            text: 'Trial',
            open: true,
        },
    ];

    pushSAEvent('visit_profile_trial_intro');

    return (
        <PageContentContainer
            title="Your profile | Trial"
            location={location}
            content={<IntroductionContent />}
            menuItems={menuItems}
            loading={loading}
        />
    );
};